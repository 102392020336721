import React from "react";
import * as d3 from "d3";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchLevels, fetchCategories } from "../actions";

import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ForwardIcon from "@material-ui/icons/Forward";

import style from "../utils/styles.js";
import "./BiasMapLegend.css";

const styles = {
  tooltip: {
    backgroundColor: "white",
    border: "1px solid #bdbdbd"
  }
};

const CustomTooltip = withStyles(styles)(Tooltip);

class BiasMapLegend extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      levels: [],
      categories: []
    };
  }
  async componentDidMount() {
    let levels = await this.getLevels();
    let categories = await this.getCategories();

    this.setState({ levels: levels, categories: categories });
  }

  getLevels = async () => {
    let levels = [];
    let resp = await this.props.fetchLevels();
    levels = resp.payload.data;

    this.setState({});
    if (resp.payload.headers["x-wp-totalpages"] > 1) {
      for (let i = 2; i <= resp.payload.headers["x-wp-totalpages"]; i++) {
        resp = await this.props.fetchLevels("&page=".concat(i));
        levels = [...levels, ...resp.payload.data];
      }
    }

    return levels;
  };

  getCategories = async () => {
    let categories = [];
    let resp = await this.props.fetchCategories();
    categories = resp.payload.data;

    this.setState({});
    if (resp.payload.headers["x-wp-totalpages"] > 1) {
      for (let i = 2; i <= resp.payload.headers["x-wp-totalpages"]; i++) {
        resp = await this.props.fetchCategories("&page=".concat(i));
        categories = [...categories, ...resp.payload.data];
      }
    }

    return categories;
  };

  highlightLevel = (event, level) => {
    event.stopPropagation();

    let levelName = level.label
      .replace(/[!%&'()*+./;<=>?\\,/:#@\t\r\n"[\]_\u007B-\u00BF-]/g, "")
      .toLowerCase()
      .replace(/  +/g, " ")
      .replace(" ", "-");

    d3.select("." + levelName)
      .selectAll(".slice_" + levelName)
      .each(function() {
        let slice = d3.select(this);
        let width = parseInt(slice.attr("stroke-width"), 10);
        slice
          .attr("stroke-width", width + style.sizes.legendHoverStrokeIncrease)
          .attr("stroke", style.colors.legendHover);
      });
  };

  unHighlightLevel = (event, level) => {
    event.stopPropagation();

    let levelName = level.label
      .replace(/[!%&'()*+./;<=>?\\,/:#@\t\r\n"[\]_\u007B-\u00BF-]/g, "")
      .toLowerCase()
      .replace(/  +/g, " ")
      .replace(" ", "-");

    d3.select("." + levelName)
      .selectAll(".slice_" + levelName)
      .each(function() {
        let slice = d3.select(this);
        let width = parseInt(slice.attr("stroke-width"), 10);
        slice
          .attr("stroke-width", width - style.sizes.legendHoverStrokeIncrease)
          .attr("stroke", style.colors.legend);
      });
  };

  highlightCategory = (event, category) => {
    event.stopPropagation();

    let categoryName = category.label
      .replace(/[!%&'()*+./;<=>?\\,/:#@\t\r\n"[\]_\u007B-\u00BF-]/g, "")
      .toLowerCase()
      .replace(/  +/g, " ")
      .replace(" ", "-");

    let result = d3.selectAll("#" + categoryName);
    let width = parseInt(result.attr("stroke-width"), 10);

    result
      .attr("stroke-width", width + style.sizes.legendHoverStrokeIncrease)
      .attr("stroke", style.colors.legendHover);
  };

  unHighlightCategory = (event, category) => {
    event.stopPropagation();

    let categoryName = category.label
      .replace(/[!%&'()*+./;<=>?\\,/:#@\t\r\n"[\]_\u007B-\u00BF-]/g, "")
      .toLowerCase()
      .replace(/  +/g, " ")
      .replace(" ", "-");

    let result = d3.selectAll("#" + categoryName);
    let width = parseInt(result.attr("stroke-width"), 10);

    result
      .attr("stroke-width", width - style.sizes.legendHoverStrokeIncrease)
      .attr("stroke", style.colors.legend);
  };

  render() {
    return (
      <div className="legend" style={{ marginTop: "10px" }}>
        <Typography variant="button" style={{ marginLeft: "10px" }}>
          LEVELS
        </Typography>
        {this.state.levels.map((level, index) => {
          return (
            <div key={level.label}>
              <CustomTooltip
                title={
                  <div className="content">
                    <div className="ui small feed">
                      <div className="event">
                        <div className="content">
                          <div
                            className="summary"
                            style={{ fontSize: "0.8rem" }}
                          >
                            <p>{level.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                placement="right"
              >
                <div
                  className="legend__container"
                  onMouseEnter={event => this.highlightLevel(event, level)}
                  onMouseOut={event => this.unHighlightLevel(event, level)}
                >
                  <Avatar
                    className="legend__avatar--category"
                    children=""
                    style={{
                      background: `linear-gradient(to right, ${style.colors.levels[index].start}, ${style.colors.levels[index].end})`,
                      width: `${style.sizes.avatarWidth}`,
                      height: `${style.sizes.avatarHeight}`,
                      pointerEvents: "none"
                    }}
                  ></Avatar>
                  <Typography
                    style={{
                      flex: "0 1 auto",
                      marginLeft: "10px",
                      pointerEvents: "none"
                    }}
                  >
                    {level.label === "" ? "Node description" : level.label}
                  </Typography>
                </div>
              </CustomTooltip>
            </div>
          );
        })}
        <Typography variant="button" style={{ marginLeft: "10px" }}>
          <span style={{ marginTop: "20px" }}>Categories</span>
        </Typography>
        {this.state.categories.map(category => {
          return (
            <div key={category.label}>
              <CustomTooltip
                title={
                  <div className="content">
                    <div className="ui small feed">
                      <div className="event">
                        <div className="content">
                          <div
                            className="summary"
                            style={{ fontSize: "0.8rem" }}
                          >
                            <p>{category.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                placement="right"
              >
                <div
                  className="legend__container"
                  onMouseEnter={event =>
                    this.highlightCategory(event, category)
                  }
                  onMouseOut={event =>
                    this.unHighlightCategory(event, category)
                  }
                >
                  <Avatar
                    variant="square"
                    className="legend__avatar--category"
                    style={{
                      backgroundColor: "transparent",
                      width: style.sizes.avatarWidth,
                      height: style.sizes.avatarHeight,
                      pointerEvents: "none"
                    }}
                  >
                    <ForwardIcon style={{ color: "black" }} />
                  </Avatar>
                  <Typography
                    style={{
                      flex: "0 1 auto",
                      marginLeft: "10px",
                      pointerEvents: "none"
                    }}
                  >
                    {category.label === ""
                      ? "Node description"
                      : category.label}
                  </Typography>
                </div>
              </CustomTooltip>
            </div>
          );
        })}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchLevels, fetchCategories }, dispatch);
}

export default connect(null, mapDispatchToProps)(BiasMapLegend);

import axios from "axios";

import { API_URL } from "../utils/config";

export const FETCH_BIASES = "FETCH_BIASES";
export const FETCH_TOURS = "FETCH_TOURS";
export const FETCH_LEVELS = "FETCH_LEVELS";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_FILTERS = "FETCH_FILTERS";
export const FETCH_MYSC = "FETCH_FILTERS";
export const FETCH_TUTORIAL = "FETCH_TUTORIAL";

export function fetchBiases(query = "") {
  const url = `${API_URL}biases&per_page=100${query}`;
  const request = axios.get(url);

  return {
    type: FETCH_BIASES,
    payload: request
  };
}

export function fetchTours(query = "") {
  const url = `${API_URL}tours&per_page=100${query}`;
  const request = axios.get(url);

  return {
    type: FETCH_TOURS,
    payload: request
  };
}

export function fetchLevels(query = "") {
  const url = `${API_URL}levels&per_page=100${query}`;
  const request = axios.get(url);

  return {
    type: FETCH_LEVELS,
    payload: request
  };
}

export function fetchCategories(query = "") {
  const url = `${API_URL}steps&per_page=100${query}`;
  const request = axios.get(url);

  return {
    type: FETCH_CATEGORIES,
    payload: request
  };
}

export function fetchFilters(query = "") {
  const url = `${API_URL}filters&per_page=100${query}`;
  const request = axios.get(url);

  return {
    type: FETCH_FILTERS,
    payload: request
  };
}

export function fetchMysc(query = "") {
  const url = `${API_URL}mysc&per_page=100${query}`;
  const request = axios.get(url);

  return {
    type: FETCH_MYSC,
    payload: request
  };
}

export function fetchTutorial(query = "") {
  const url = `${API_URL}tutorial&per_page=100${query}`;
  const request = axios.get(url);

  return {
    type: FETCH_TUTORIAL,
    payload: request
  };
}

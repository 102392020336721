import React from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";

import BiasMapTutorial from "./BiasMapTutorial";
import BiasMapWrapper from "./BiasMapWrapper";
import BiasMapTours from "./BiasMapTours";
import BiasMapFilters from "./BiasMapFilters";
import SearchBias from "./SearchBias";

import BiasMapDownload from "./BiasMapDownload";
import BiasMapLegend from "./BiasMapLegend";

import "./App.css";
import "intro.js/introjs.css";
import style from "../utils/styles.js";
import { WEB_URL } from "../utils/config";
import { BASE_URL } from "../utils/config";

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: style.colors.base
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  toolbarButton: {
    marginRight: 20,
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(255,255,255, .2)",
      borderColor: "#0062cc",
      color: "white",
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "rgba(255,255,255, .2)",
      borderColor: "rgba(255,255,255, .2)",
      color: "white"
    }
  },
  title: {
    flexGrow: 1,
    fontSize: "2rem",
    fontFamily: "Impact",
    fontWeight: 400,
    textShadow:
      "2px 3px 2px rgba(0,0,0,.5)" /* 50% black coming from the bottom */
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: "100%"
  },
  appBarSpacer: theme.mixins.toolbar,
  toolbar: theme.mixins.toolbar,
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing(1)
  }
}));

export default function Dashboard() {
  const classes = useStyles();
  const [tours, setTours] = React.useState("");
  const [links, setLinks] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [disabled, setDisabled] = React.useState(true);
  const [checkedA, setCheckedA] = React.useState(false);
  const [modal, setModal] = React.useState(false);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  function setToursData(tours, links) {
    setTours(tours);
    setLinks(links);
  }

  const handleChange = () => event => {
    setCheckedA(!checkedA);
  };

  function switchOff() {
    setCheckedA(false);
  }

  function switchOn() {
    setCheckedA(true);
  }

  function showWelcomeModal(show) {
    setModal(show);
  }

  function loading() {
    if (disabled) {
      return <LinearProgress size={27} />;
    }
    return "";
  }

  function onStart() {
    setModal(false);
    setCheckedA(true);
  }

  function onStop() {
    setModal(false);
    setCheckedA(false);
  }

  let x = window.matchMedia("(min-width: 990px)");
  let y = window.matchMedia("(min-height: 690px)");

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Dialog
        onClose={() => setModal(false)}
        aria-labelledby="simple-dialog-title"
        open={modal}
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <h2>{"Bias-map"}</h2>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "black" }}
          >
            Welcome to the interactive map of cognitive biases in decision
            making. This short tutorial shows you the main functionalities of
            the map and how to create your own customized PDF version.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onStart} color="primary">
            Start Tutorial
          </Button>
          <Button onClick={onStop} color="secondary" autoFocus>
            Do it later...
          </Button>
        </DialogActions>
      </Dialog>
      <BiasMapTutorial enabled={checkedA} disableSwitch={switchOff} />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography color="inherit" noWrap className={classes.title}>
            A map of cognitive biases in decision making
          </Typography>
          <Button
            className={classes.toolbarButton}
            target="_blank"
            href={BASE_URL}
          >
            Home
          </Button>
          <Button
            className={classes.toolbarButton}
            target="_blank"
            href={BASE_URL + "/about-us"}
          >
            About us
          </Button>
          <Button
            className={classes.toolbarButton}
            target="_blank"
            href={BASE_URL + "/contact-us"}
          >
            Contact us
          </Button>
          <SearchBias disabled={disabled} />
          <BiasMapDownload disabled={disabled} selected={selected} />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.toolbar} />

        <Divider />
        {loading()}
        <div
          style={{
            padding: "15px",
            textAlign: "center"
          }}
        >
          <button
            variant="contained"
            color="secondary"
            className="btn btn-mat btn-tutorial .psuedo-text"
            style={{ padding: 0 }}
            onClick={handleChange()}
            value="checkedB"
            disabled={disabled}
          >
            TUTORIAL
          </button>
        </div>
        <Divider style={{ backgroundColor: "#757575" }} />
        <div className="filters-container">
          <Typography
            style={{ marginTop: "10px", marginLeft: "10px" }}
            variant="h6"
            m="40px"
          >
            <i className="filter icon" style={{ marginRight: "10px" }}></i>
            Filters
          </Typography>
          <BiasMapFilters disabled={disabled} />
        </div>
        <Divider style={{ backgroundColor: "#757575" }} />
        <div className="tours-container">
          <Typography
            style={{ marginTop: "10px", marginLeft: "10px" }}
            variant="h6"
            m="40px"
          >
            <i className="paper plane icon" style={{ marginRight: "10px" }}></i>
            Tours
          </Typography>
          <BiasMapTours
            className="step-3"
            setToursData={setToursData}
            selectedBiases={selected}
            disabled={disabled}
          />
        </div>
        <Divider style={{ backgroundColor: "#757575" }} />
        <div className="legend-container">
          <Typography
            style={{ marginTop: "10px", marginLeft: "10px" }}
            variant="h6"
            m="40px"
          >
            <i className="th list icon" style={{ marginRight: "10px" }}></i>
            Legend
          </Typography>
          <BiasMapLegend />
        </div>
      </Drawer>
      {x.matches && y.matches ? (
        <main className={classes.content}>
          <div id="main">
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper className={fixedHeightPaper} elevation={0}>
                    <BiasMapWrapper
                      tours={tours}
                      links={links}
                      disabled={disabled}
                      setDisabled={setDisabled}
                      tutorialOn={switchOn}
                      setSelected={setSelected}
                      showWelcomeModal={showWelcomeModal}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </div>
        </main>
      ) : (
        <Dialog
          fullScreen
          open={true}
          onClose={() => {}}
          aria-labelledby="fullscreen-dialog-wrong-device-size"
        >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ height: "100vh", width: "100vw" }}
          >
            <ReportProblemRoundedIcon
              color="secondary"
              style={{ marginBottom: "20px", fontSize: 80 }}
            />
            <Typography
              paragraph
              style={{ marginLeft: "50px", marginRight: "50px" }}
            >
              The map is only viewable on PCs, laptops or tablets with a
              resolution of at least 1000x700.
            </Typography>
            <Typography
              paragraph
              style={{ marginLeft: "50px", marginRight: "50px" }}
            >
              Please click the link below to view a list of all the biases.
            </Typography>
            <Button color="primary" href={WEB_URL}>
              www.visual-literacy.org
            </Button>
          </Grid>
        </Dialog>
      )}
    </div>
  );
}

const style = {
  colors: {
    base: "#3f51b5",
    bias: "#fff",
    selectedBias: "#00b0ff",
    currentBiasStroke: "yellow",
    tourBiasStroke: "#000",
    tourPathColor: "red",
    tourPathColorHovered: "#b71c1c",
    tourArrowColor: "red",
    tourArrowColorHovered: "#b71c1c",
    levels: [
      { start: "#3f51b5", end: "#65a0ec" },
      { start: "#4b4cdd", end: "#e76de5" },
      { start: "#e79d6d", end: "#dd4b92" }
    ],
    tutorialButtonColor: "#c5cae9",
    legend: "#000",
    legendHover: "#ffff00"
  },
  sizes: {
    pathStroke: 3,
    externalPathStroke: 3,
    standardBiasStrokeWidth: 10,
    tourBiasStrokeWidth: 60,
    currentBiasStrokeWidth: 100,
    legendHoverStrokeIncrease: 50,

    avatarWidth: "22px",
    avatarHeight: "22px"
  }
};

export default style;

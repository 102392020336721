import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchTours } from "../actions";
import * as d3 from "d3";

import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";

import style from "../utils/styles.js";

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const styles = {
  tooltip: {
    backgroundColor: "white",
    border: "1px solid #bdbdbd"
  }
};

const CustomTooltip = withStyles(styles)(Tooltip);

class BiasMapTours extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tours: [],
      links: [],
      selectedTour: "",
      previousTour: {
        biases: []
      },
      open: false
    };
  }
  async componentDidMount() {
    let tours = await this.getTours();

    let links = [];

    tours.forEach(tour => {
      let link = [];
      tour.biases.forEach((bias, index) => {
        if (index === tour.biases.length - 1) return;
        link.push({
          source: tour.biases[index].id,
          target: tour.biases[index + 1].id
        });
      });
      links.push(link);
    });

    this.setState({ tours: tours, links: links });

    this.props.setToursData(tours, links);
  }

  getTours = async () => {
    let tours = [];
    let resp = await this.props.fetchTours();
    tours = resp.payload.data;

    if (resp.payload.headers["x-wp-totalpages"] > 1) {
      for (let i = 2; i <= resp.payload.headers["x-wp-totalpages"]; i++) {
        resp = await this.props.fetchTours("&page=".concat(i));
        tours = [...tours, ...resp.payload.data];
      }
    }

    return tours;
  };

  handleChange = event => {
    // reset tours visibility
    d3.selectAll(".tours")
      .selectAll("line")
      .style("display", "none")
      .style("opacity", 0);

    this.state.previousTour.biases.forEach(bias => {
      d3.select("#circle-" + bias.id)
        .attr("fill", style.colors.bias)
        .attr("stroke-width", style.sizes.standardBiasStrokeWidth)
        .attr("stroke", style.colors.tourBiasStroke);
    });

    this.props.selectedBiases.forEach(bias => {
      d3.select("#circle-" + bias.id).attr("fill", style.colors.selectedBias);
    });

    let selectedTour = event.target.value;
    if (selectedTour) {
      this.setState({ selectedTour: selectedTour });

      let id = "#tour-" + selectedTour.id;

      // make visible the selected tour
      d3.select(id)
        .selectAll(".link")
        .style("opacity", 1)
        .style("display", "initial")
        .each(function(d, i) {
          var totalLength = d3
            .select(this)
            .node()
            .getTotalLength();

          d3.select(this)
            // Set the line pattern to be an long line followed by an equally long gap
            .attr("stroke-dasharray", totalLength + " " + totalLength)
            // Set the intial starting position so that only the gap is shown by offesetting by the total length of the line
            .attr("stroke-dashoffset", totalLength)
            // Then the following lines transition the line so that the gap is hidden...
            .transition()
            .delay(i * 700)
            .duration(600)
            .ease(d3.easeLinear) //Try linear, quad, bounce... see other examples here - http://bl.ocks.org/hunzy/9929724
            .attr("stroke-dashoffset", 0);

          d3.select("#arrow-tour-" + selectedTour.id + "-link-" + i)
            .transition()
            .delay(i * 700 + 600)
            .duration(100)
            .style("opacity", 1);
        });

      selectedTour.biases.forEach(bias => {
        d3.select("#circle-" + bias.id)
          .attr("fill", style.colors.selectedBias)
          .attr("stroke-width", style.sizes.tourBiasStrokeWidth)
          .attr("stroke", "#263238");
      });

      this.setState({ previousTour: selectedTour });
      this.setState({ open: true });
    } else {
      this.setState({ selectedTour: selectedTour });
      this.setState({ previousTour: { biases: [] } });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div className="tours">
        <FormControl style={{ marginTop: "10px", marginLeft: "20px" }}>
          <Select
            value={this.state.selectedTour}
            onChange={this.handleChange}
            displayEmpty
            style={{ width: "130px !important", marginBottom: "20px" }}
            disabled={this.props.disabled}
          >
            <MenuItem value={""}>Select a tour...</MenuItem>
            {this.state.tours.map((tour, index) => {
              return (
                <MenuItem key={tour.id} value={tour} style={{ padding: 0 }}>
                  <CustomTooltip
                    title={
                      <div className="content">
                        <div className="ui small feed">
                          <div className="event">
                            <div className="content">
                              <div
                                className="summary"
                                style={{ fontSize: "0.8rem" }}
                              >
                                <p>{tour.description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    placement="right"
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "6px 16px 6px 16px"
                      }}
                    >
                      {tour.label}
                    </div>
                  </CustomTooltip>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Snackbar
          open={this.state.open}
          message="HINT: Select the paths of the tour to explore the involved biases."
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={7000}
          onClose={this.handleClose}
          TransitionComponent={TransitionLeft}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        ></Snackbar>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchTours }, dispatch);
}

export default connect(null, mapDispatchToProps)(BiasMapTours);

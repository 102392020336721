import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchLevels, fetchMysc, fetchTutorial } from "../actions";

import Tour from "reactour";
import ModalVideo from "react-modal-video";
import Button from "@material-ui/core/Button";

import "./BiasMapTutorial.css";

class BiasMapTutorial extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      steps: [],
      levels: [],
      process: {},
      tutorial: {},
      stepsEnabled: false,
      initialStep: 0,
      isOpen: false
    };
  }

  async componentDidMount() {
    let levels = await this.getLevels();
    let process = await this.getMysc();
    let tutorial = await this.getTutorial();

    this.setState({ levels: levels, process: process, tutorial: tutorial });

    let steps = [];

    steps.push({
      selector: ".central-flow-container",
      position: "left",
      rounded: "5px",
      content: () => (
        <div style={{ margin: "5px", padding: "5px" }}>
          <h4 style={{ marginBottom: "10px" }}>{this.state.process.label}</h4>
          {this.state.process.description}
          {this.state.tutorial.video !== "" ? (
            <div
              className="tutorial-video"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <Button
                variant="outlined"
                className="ui secondary basic button"
                onClick={this.openModal}
              >
                <i
                  className="video icon"
                  style={{ margin: 0, marginRight: "10px" }}
                ></i>{" "}
                Watch
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      )
    });

    levels.forEach((level, index) => {
      steps.push({
        selector: ".level-" + (index + 1),
        rounded: "5px",
        content: () => (
          <div style={{ margin: "5px", padding: "5px" }}>
            <h4 style={{ marginBottom: "10px" }}>
              {level.label}
              <br></br>({level.sublabel})
            </h4>
            <div
              className="extra content"
              style={{ fontStyle: "italic", margin: "10px" }}
            >
              <i className="quote left icon"></i>
              {level.citation + " "} <i className="quote right icon"></i>
            </div>
            {level.description}
            <img
              src={"level-0" + (index + 1) + ".svg"}
              alt={"avatar-" + (index + 1)}
            ></img>
          </div>
        )
      });
    });

    steps = steps.concat([
      {
        selector: ".filters-container",
        rounded: "5px",
        content: () => (
          <div style={{ margin: "5px", padding: "5px" }}>
            <h4 style={{ marginBottom: "10px" }}>Filters</h4>
            This is the filters section, activate the switches to filter the
            biases.
          </div>
        )
      },
      {
        selector: ".tours-container",
        rounded: "5px",
        content: (
          <div style={{ margin: "5px", padding: "5px" }}>
            <h4 style={{ marginBottom: "10px" }}>Tours</h4>
            Here you can select the tour to be displayed.
          </div>
        )
      },
      {
        selector: ".search",
        rounded: "5px",
        content: (
          <div style={{ margin: "5px", padding: "5px" }}>
            <h4 style={{ marginBottom: "10px" }}>Search</h4>
            To easily search a bias, just type some text.
          </div>
        )
      },
      {
        selector: ".selected",
        rounded: "5px",
        content: (
          <div style={{ margin: "5px", padding: "5px" }}>
            <h4 style={{ marginBottom: "10px" }}>Bias selection history</h4>
            The expansion panel above the map keeps track of all the biases that
            you selected during this session. If you want to remove a bias from
            the panel, just click on the 'x' button.
            <img
              src="delete_bias.png"
              alt="delete"
              style={{ width: "100%", padding: "10px 55px" }}
            ></img>
          </div>
        )
      },
      {
        selector: ".export",
        rounded: "5px",
        content: (
          <div style={{ margin: "5px", padding: "5px" }}>
            <h4 style={{ marginBottom: "10px" }}>
              Exporting your individual bias map
            </h4>
            The biases inside the expansion panel can be exported in PDF format.
            Feel free to add or remove the biases that match or do not match
            your interest.
          </div>
        )
      }
    ]);

    this.setState({
      steps: steps
    });
  }

  getLevels = async () => {
    let levels = [];
    let resp = await this.props.fetchLevels();
    levels = resp.payload.data;

    this.setState({});

    if (resp.payload.headers["x-wp-totalpages"] > 1) {
      for (let i = 2; i <= resp.payload.headers["x-wp-totalpages"]; i++) {
        resp = await this.props.fetchLevels("&page=".concat(i));
        levels = [...levels, ...resp.payload.data];
      }
    }

    return levels;
  };

  getMysc = async () => {
    let mysc = [];
    let resp = await this.props.fetchMysc();

    mysc = resp.payload.data;

    this.setState({});

    let process = {};
    mysc.forEach(item => {
      if (item.label === "Process") process = item;
    });

    return process;
  };

  getTutorial = async () => {
    let tutorial = [];
    let resp = await this.props.fetchTutorial();

    tutorial = resp.payload.data;

    this.setState({});

    if (tutorial && tutorial.length > 0) {
      return tutorial[0];
    }

    return {};
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
    this.props.disableSwitch();
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  render() {
    return (
      <div>
        <Tour
          steps={this.state.steps}
          isOpen={this.props.enabled}
          onRequestClose={this.onExit}
        />
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId={this.state.tutorial.video}
          onClose={() => this.setState({ isOpen: false })}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { fetchLevels, fetchMysc, fetchTutorial },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(BiasMapTutorial);

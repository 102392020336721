import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchFilters } from "../actions";
import * as d3 from "d3";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";

const styles = {
  tooltip: {
    backgroundColor: "white",
    border: "1px solid #bdbdbd"
  }
};

const CustomTooltip = withStyles(styles)(Tooltip);

class BiasMapFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: [],
      switchStates: {},
      checkboxState: false,
      firstRendering: false,
      disabled: false,
      open: false,
      anchorEl: null,
      toDisplayBiases: null
    };
  }

  async componentDidMount() {
    let filters = await this.getFilters();
    let switchStates = {};

    filters.forEach((filter, index) => {
      switchStates["switch-" + index] = false;
    });

    this.setState({ filters: filters, switchStates: switchStates });
  }

  getFilters = async () => {
    let filters = [];
    let resp = await this.props.fetchFilters();
    filters = resp.payload.data;

    if (resp.payload.headers["x-wp-totalpages"] > 1) {
      for (let i = 2; i <= resp.payload.headers["x-wp-totalpages"]; i++) {
        resp = await this.props.fetchFilters("&page=".concat(i));
        filters = [...filters, ...resp.payload.data];
      }
    }

    return filters;
  };

  handleChange(filterIndex, event, checked) {
    const states = Object.assign({}, this.state.switchStates);

    states["switch-" + filterIndex] = checked;

    this.setState({
      switchStates: states
    });

    let keys = Object.keys(states);
    let unactiveFilters = [];
    let activeFilters = [];

    keys.forEach((key, index) => {
      if (!states[key]) {
        unactiveFilters.push(this.state.filters[index].label);
      }
    });
    keys.forEach((key, index) => {
      if (states[key]) {
        activeFilters.push(this.state.filters[index].label);
      }
    });

    if (unactiveFilters.length === this.state.filters.length) {
      let biases = d3.select("#mainContainer-2").selectAll("circle");

      biases
        .transition()
        .duration(50)
        .style("opacity", 1);

      biases.attr("visibility", "visible");

      console.log("All filters unactived!", biases);
      this.setState({ toDisplayBiases: biases }, () => {
        this.handleChechboxChange(null);
      });
    } else {
      console.log("filter activated");
      let biases = d3.select("#mainContainer-2").selectAll("circle");
      console.log("biases", biases);

      let toDisplayBiases = biases.filter((bias, index) => {
        let active = 0;

        if (bias.b_type) {
          bias.b_type.forEach(type => {
            if (activeFilters.includes(type.label)) {
              active++;
            }
          });
        }

        if (this.state.checkboxState) {
          return active > 0 && bias.video !== "";
        }

        return active > 0;
      });

      this.setState({ disabled: true, toDisplayBiases: toDisplayBiases });

      let component = this;

      d3.select("#mainContainer-2")
        .selectAll("circle")
        .transition()
        .duration(150)
        .style("opacity", 0);

      d3.select("#mainContainer-2")
        .selectAll("circle")
        .transition()
        .delay(150)
        .attr("visibility", "hidden");

      toDisplayBiases.each(function(d, index) {
        d3.select(this)
          .transition()
          .duration(150)
          .style("opacity", 1);
      });

      setTimeout(() => {
        component.setState({ disabled: false });
      }, 1500);

      toDisplayBiases.each(function(d) {
        d3.select(this).attr("visibility", "visible");
      });

      this.setState({ toDisplayBiases: toDisplayBiases }, () => {
        this.handleChechboxChange(null);
      });
    }
  }

  handleChechboxChange = event => {
    if (event !== null) {
      console.log("checkbox pressed");
      let checked = event.target.checked;
      this.setState({ checkboxState: checked }, this.showBiases(checked));
    } else {
      let checked = this.state.checkboxState;
      console.log("filter changed", checked);
      this.showBiases(checked);
    }
  };

  showBiases(show) {
    let biases = this.state.toDisplayBiases;

    console.log("biases ", biases);

    if (biases === null) {
      biases = d3.select("#mainContainer-2").selectAll("circle");

      this.setState({ toDisplayBiases: biases }, () => {
        if (show) {
          console.log("show video null");
          let videoBiases = biases.filter(function(bias) {
            return bias.video;
          });

          console.log("video null", videoBiases);

          let noVideoBiases = biases.filter(function(bias) {
            return !bias.video;
          });

          console.log("no video null", noVideoBiases);

          videoBiases.each(function(d) {
            d3.select(this)
              .style("opacity", 1)
              .attr("visibility", "visible");
          });

          noVideoBiases.each(function(d) {
            d3.select(this)
              .style("opacity", 0)
              .attr("visibility", "hidden");
          });
        } else {
          console.log("show all");
          biases.each(function(d) {
            d3.select(this)
              .style("opacity", 1)
              .attr("visibility", "visible");
          });
        }
      });
    } else {
      if (show) {
        console.log("show video");
        let videoBiases = biases.filter(function(bias) {
          return bias.video;
        });

        console.log("video", videoBiases);

        let noVideoBiases = biases.filter(function(bias) {
          return !bias.video;
        });

        console.log("no video", noVideoBiases);

        videoBiases.each(function(d) {
          d3.select(this)
            .style("opacity", 1)
            .attr("visibility", "visible");
        });

        noVideoBiases.each(function(d) {
          d3.select(this)
            .style("opacity", 0)
            .attr("visibility", "hidden");
        });
      } else {
        console.log("show all");
        biases.each(function(d) {
          d3.select(this)
            .style("opacity", 1)
            .attr("visibility", "visible");
        });
      }
    }
  }

  showPopover = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
    this.setState({ open: true });
  };

  hidePopover = (event, value) => {
    event.stopPropagation();
    this.setState({ anchorEl: value });
    this.setState({ open: false });
  };

  render() {
    return (
      <div className="filters">
        <List>
          <FormControl component="fieldset">
            <FormGroup>
              {this.state.filters.map((filter, index) => {
                return (
                  <div key={filter.label}>
                    <CustomTooltip
                      title={
                        <div className="content">
                          <div className="ui small feed">
                            <div className="event">
                              <div className="content">
                                <div
                                  className="summary"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  <p>{filter.description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      placement="right"
                    >
                      <FormControlLabel
                        style={{ marginLeft: "10px" }}
                        control={
                          <Switch
                            className="filters-switch"
                            checked={this.state.switchStates["switch-" + index]}
                            onChange={(event, checked) =>
                              this.handleChange(index, event, checked)
                            }
                            value={filter.label}
                            color="primary"
                            inputProps={{ "aria-label": "primary checkbox" }}
                            style={{ marginRight: "200px" }}
                            disabled={
                              this.props.disabled || this.state.disabled
                            }
                          />
                        }
                        label={filter.label}
                        labelPlacement="end"
                      />
                    </CustomTooltip>
                  </div>
                );
              })}
            </FormGroup>
            <Divider style={{ backgroundColor: "#757575", marginTop: "5px" }} />
            <FormGroup style={{ paddingLeft: "20px", marginTop: "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.checkboxState}
                    onChange={event => this.handleChechboxChange(event)}
                    color="primary"
                    value="secondary"
                    disabled={this.props.disabled || this.state.disabled}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Show biases with short video examples"
              />
            </FormGroup>
          </FormControl>
        </List>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchFilters }, dispatch);
}

export default connect(null, mapDispatchToProps)(BiasMapFilters);

import { combineReducers } from "redux";

import biases from "./biases-reducer";
import tours from "./tours-reducer";
import levels from "./levels-reducer";
import categories from "./categories-reducer";

const rootReducer = combineReducers({
  biases,
  tours,
  levels,
  categories
});

export default rootReducer;

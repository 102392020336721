import React from "react";

import * as d3 from "d3";

class SearchBias extends React.Component {
  search(event) {
    const {
      target: { value }
    } = event;
    let lower = value.toLowerCase();

    let biases = d3.select("#mainContainer-2").selectAll("circle");

    biases
      .transition()
      .duration(400)
      .attr("stroke-width", 10)
      .attr("stroke", "black");

    let filteredBiases = biases.filter(function(d) {
      let words = lower.split(" ");
      let flag = true;

      words.forEach(word => {
        let tempFlag =
          d.cognitive_bias.toLowerCase().includes(word) ||
          d.description.toLowerCase().includes(word) ||
          d.managerial_axis.toLowerCase().includes(word) ||
          d.psychological_axis.toLowerCase().includes(word);

        flag = flag && tempFlag;
      });

      return flag;
    });

    if (filteredBiases._groups.length !== 0 && lower !== "") {
      filteredBiases.each(function(d) {
        d3.select(this)
          .transition()
          .duration(400)
          .attr("stroke-width", 90)
          .attr("stroke", "#263238");
      });
    }
  }

  render() {
    return (
      <div className="search">
        <div
          className={`ui search ${this.props.disabled ? "disabled" : ""}`}
          style={{
            border: "1px solid white",
            padding: "10px",
            borderRadius: "10px"
          }}
        >
          <div className="right menu">
            <div className="ui right aligned category search item">
              <div className="ui transparent inverted large icon input">
                <input
                  onChange={event => this.search(event)}
                  className="prompt"
                  type="text"
                  placeholder="Search for a bias..."
                />
                <i className="search link icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SearchBias;

import React, { Component } from "react";

import * as d3 from "d3";

import BiasMapToolbar from "./BiasMapToolbar";

import style from "../utils/styles.js";
import "./BiasMap.css";

class BiasMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      containerW: 0,
      containerH: 0,
      biases: {},
      selectedBiases: []
    };
  }

  componentDidUpdate() {
    if (this.props.ready && this.props.firstRendering) this.createBiasMap();
  }

  createBiasMap = () => {
    let component = this;

    const containerW = this.refs.child.parentNode.clientWidth;
    const containerH = this.refs.child.parentNode.clientHeight;

    // CHECK IF EDGE (USED TO CENTER THE CENTRAL FLOW SVG ON EDGE)

    // Internet Explorer 6-11
    let isIE = /*@cc_on!@*/ false || !!document.documentMode;
    // Edge 20+
    let isEdge = !isIE && !!window.StyleMedia;

    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isIEedge = winNav.userAgent.indexOf("Edge") > -1;

    // ARCS DATA SETUP

    let datasets = [];

    this.props.levels.forEach((level, index) => {
      let levelStructure = {
        id: index,
        label: level.label,
        categories: []
      };
      level.categories.forEach((category, index) => {
        let [step] = Object.entries(category.step);

        let categoryStructure = {
          id: index,
          count: 10,
          label: category.label,
          description: category.description,
          additional: category.additional,
          biases: [],
          step: step[1]
        };

        categoryStructure.biases = this.props.biases.filter(bias => {
          if (bias.category[0] === undefined) {
            return {};
          }
          return bias.category[0].label === category.label;
        });

        levelStructure.categories.push(categoryStructure);
      });

      datasets.push(levelStructure);
    });

    // PARAMETERS

    var delta = Math.min(containerW, containerH) / 3;

    let radius1 = Math.min(containerW, containerH) * 2.1;
    let radius2 = Math.min(containerW, containerH) * 4.35;
    let radius3 = Math.min(containerW, containerH) * 6.6;
    let radii = [radius1, radius2, radius3];

    let biasRadius = Math.min(containerW, containerH) / 9.5;

    // D3 RENDERING

    // ZOOM SETUP

    function zoomed() {
      if (d3.event.transform.k > 0.3)
        d3.select("rect")
          .transition()
          .duration(1000)
          .style("fill", "white")
          .style("opacity", 1);

      if (d3.event.transform.k < 0.45)
        d3.select("rect")
          .transition()
          .duration(200)
          .style("fill", "url(#bg)")
          .style("opacity", 1);

      svg.attr("transform", d3.event.transform);
    }

    const zoom = d3.zoom().on("zoom", zoomed);

    // MAIN SVG: Contains all the elements of the map
    let svg = d3
      .select("#biasmap")
      .append("svg")
      .attr("id", "biasmap-svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", "0 0 " + containerW + " " + containerH)
      .attr("preserveAspectRatio", "xMinYMin")
      .call(zoom)
      .append("g")
      .attr("id", "mainContainer");

    zoom.filter(function() {
      return d3.event.type !== "mousedown" && d3.event.type !== "wheel";
    });

    // BACKGROUND FILL ILLUSTRATION

    svg
      .append("defs")
      .append("pattern")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 1)
      .attr("height", 1)
      .attr("id", "bg")

      .append("image")
      .attr("xlink:href", "eye_base_min.svg")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("width", radii[2] * 0.65)
      .attr("height", radii[2] * 0.65)
      .attr("viewBox", "0 0 " + radii[2] * 2 + " " + radii[2] * 2);

    // BACKGROUND RECTANGLE: represents the zoomable area

    let rectBBox = null,
      width = 0,
      height = 0;

    svg
      .append("rect")
      .attr("x", 120)
      .attr("y", -80)
      .attr("width", radii[2] * 0.65)
      .attr("height", radii[2] * 0.65)
      .attr("viewBox", "0 0 " + containerW * 3 + " " + containerH * 3)
      .attr("preserveAspectRatio", "xMidYMid slice")
      .attr("transform", function(d, i) {
        rectBBox = d3
          .select(this)
          .node()
          .getBBox();
        width = containerW / 2 - rectBBox.width / 2;
        height = containerH / 2 - rectBBox.height / 2;
        return "translate( " + width + "," + height + " )";
      })
      .style("fill", "url(#bg)")
      .style("opacity", 0)
      .attr("id", "background");

    // TOOLTIP SETUP
    let div = d3
      .select("#biasmap")
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("position", "absolute")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("z-index", 100)
      .style("padding", "10px")
      .style("pointer-events", "none")
      .attr(
        "transform",
        "translate(" + containerW / 2 + "," + containerH / 2 + ")"
      );

    // SVG media container
    var defs = d3
      .select("#mainContainer")
      .append("defs")
      .attr("id", "main-defs");

    // Central flow SVG

    defs
      .append("pattern")
      .attr("id", "flow")
      .attr("width", 1)
      .attr("height", 1)
      .attr("patternContentUnits", "objectBoundingBox")
      .append("svg:image")
      .attr("xlink:xlink:href", "central_flow.svg")
      .attr("height", function() {
        if (
          isChromium !== null &&
          typeof isChromium !== "undefined" &&
          vendorName === "Google Inc." &&
          isOpera === false &&
          isIEedge === false
        ) {
          return 1.01;
        }
        if (isEdge) return 1.6;
        return 1.337;
      })
      .attr("width", function() {
        if (
          isChromium !== null &&
          typeof isChromium !== "undefined" &&
          vendorName === "Google Inc." &&
          isOpera === false &&
          isIEedge === false
        ) {
          return 3.999;
        }
        if (isEdge) return 2;
        return 1.337;
      })
      .attr("x", function() {
        if (
          isChromium !== null &&
          typeof isChromium !== "undefined" &&
          vendorName === "Google Inc." &&
          isOpera === false &&
          isIEedge === false
        ) {
          return -1.486;
        }
        if (isEdge) return -0.48;
        return -0.148;
      })
      .attr("y", function() {
        if (
          isChromium !== null &&
          typeof isChromium !== "undefined" &&
          vendorName === "Google Inc." &&
          isOpera === false &&
          isIEedge === false
        ) {
          console.log("Is chrome");
          return -0.003;
        }
        if (isEdge) return 0;
        return -0.168;
      })
      .attr("preserveAspectRatio", "xMidYMid");

    d3.select("#mainContainer")
      .append("circle")
      .attr("class", "level-3")
      .attr("r", 0)
      .style("fill", "#c5cae9")
      .attr(
        "transform",
        "translate(" + containerW / 2 + "," + containerH / 2 + ") scale(0.047)"
      )
      .transition()
      .delay(6000)
      .duration(1500)
      .attr("r", radii[0] * 5);

    d3.select("#mainContainer")
      .append("circle")
      .attr("class", "level-2")
      .attr("r", 0)
      .style("fill", "transparent")
      .attr(
        "transform",
        "translate(" + containerW / 2 + "," + containerH / 2 + ") scale(0.047)"
      )
      .transition()
      .attr("r", radii[0] * 3.6);

    d3.select("#mainContainer")
      .append("circle")
      .attr("class", "level-1")
      .attr("r", 0)
      .style("fill", "transparent")
      .attr(
        "transform",
        "translate(" + containerW / 2 + "," + containerH / 2 + ") scale(0.047)"
      )
      .transition()
      .attr("r", radii[0] * 2.65);

    var transform = d3.zoomIdentity
      .translate(containerW * 0.35, containerH * 0.35)
      .scale(0.3);

    var transform2 = d3.zoomIdentity.scale(1);

    d3.select("#biasmap-svg")
      .transition()
      .delay(9000)
      .duration(4000)
      .call(zoom.transform, transform);

    d3.select("#biasmap-svg")
      .transition()
      .delay(15000)
      .duration(2000)
      .call(zoom.transform, transform2);

    // CENTER FLOW

    d3.select("#mainContainer")
      .append("g")
      .attr("class", "central-flow-container")
      .append("circle")
      .attr("id", "central-flow")
      .attr("r", 0)
      .on("mouseover", function(d) {
        div
          .transition()
          .duration(400)
          .style("opacity", 1)
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY + "px");
        div.html(
          `
          <div class="ui card">
            <div class="content center">
              <h3 class="ui header" style="margin:0"><ul>${component.props.process.label}</ul></h3>
            </div>
            <div class="content">
              <div class="ui small feed">
                <div class="event">
                  <div class="content">
                    <div class="summary">
                      <p>${component.props.process.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          `
        );
      })
      .on("mouseout", function(d) {
        div
          .transition()
          .duration(10)
          .style("opacity", 0);
      })
      // .style("fill", "url(#flow)")
      .attr(
        "transform",
        "translate(" + containerW / 2 + "," + containerH / 2 + ") scale(0.047)"
      )
      .transition()
      .duration(1500)
      .attr("r", radii[0] * 1.8);

    // DONUTS SETUP

    let pies = [];
    let arcs = [];
    let circleArcs = [];
    let groups = [];

    d3.select("#mainContainer")
      .append("g")
      .attr("id", "mainContainer-2");

    for (let i = 0; i < this.props.levels.length; i++) {
      pies.push(
        d3
          .pie()
          .startAngle((-72 * Math.PI) / 180)
          .endAngle((-72 * Math.PI) / 180 + 2 * Math.PI)
          .value(function(d) {
            return d.count;
          })
          .padAngle(0.0)
          .sort(null)
      );

      arcs.push(
        d3
          .arc()
          .innerRadius(radii[i] + delta * 5)
          .outerRadius(radii[i] + delta * 10)
        // .cornerRadius(10)
      );

      circleArcs.push(
        d3
          .arc()
          .innerRadius(radii[i] + delta * 5)
          .outerRadius(radii[i] + delta * 9.5)
        // .cornerRadius(10)
      );

      groups.push(
        d3
          .select("#mainContainer-2")
          .append("g")
          .attr("id", "group-" + i)
          .attr("class", function() {
            let groupName = component.props.levels[i].label
              .toLowerCase()
              .replace(" ", "-");
            return groupName;
          })
      );
    }

    // DONUT CHARTS

    let slices = [];

    // create donut slices
    for (let i = 0; i < groups.length; i++) {
      slices.push(
        groups[i]
          .selectAll(".arc")
          .data(pies[i](datasets[0].categories))
          .enter()
          .append("g")
          .attr("id", function(d, n) {
            return "g" + i + "-slice-" + n;
          })
          .attr(
            "transform",
            "translate(" +
              containerW / 2 +
              "," +
              containerH / 2 +
              ") scale(0.047)"
          )
          .attr("class", "arc")
      );
    }

    // Slices axis rotation: in this way the label angle matches the slice angle
    for (let i = 0; i < groups.length; i++) {
      slices[i]
        .append("path")
        .attr("d", arcs[i])
        .attr("stroke", style.colors.legend)
        .attr("stroke-width", 15)
        .attr("pointer-events", "none")
        .style("opacity", 0)
        .on("click", function(d, j) {
          let thisPath = d3.select(this);
          let clicked = thisPath.classed("clicked");
          thisPath.classed("clicked", !clicked);

          if (thisPath.classed("clicked")) {
            thisPath
              .transition()
              .duration(100)
              .attr("stroke-width", 70);
          } else {
            thisPath
              .transition()
              .duration(100)
              .attr("stroke-width", 15);
          }

          d.data = datasets[i].categories[j];
          component.props.openCategorySide(d.data);
        })
        .on("mouseover", function(d, j) {
          div
            .transition()
            .duration(400)
            .style("opacity", 1)
            .style("left", d3.event.pageX + "px")
            .style("top", d3.event.pageY + "px");

          d3.select(this)
            .transition()
            .duration(250)
            .attr("transform", "scale(1.010)");

          div.html(
            `
              <div class="ui card">
                <div class="content center">
                  <h3 class="ui header" style="margin:0, color: ${style.colors.base}"><ul>${datasets[i].categories[j].label}</ul></h3>
                </div>
                <div class="content">
                  <div class="ui small feed">
                    <div class="event">
                      <div class="content">
                        <div class="summary">
                          <p>${datasets[i].categories[j].description}</p>
                        </div>
                      </div>
                    </div>
                    <br/>
                    <div class="event">
                      <div class="content">
                          <p>${datasets[i].categories[j].additional}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              `
          );
        })
        .on("mouseout", function(d) {
          div
            .transition()
            .duration(10)
            .style("opacity", 0);

          d3.select(this)
            .transition()
            .duration(250)
            .attr("transform", "scale(1)");
        })
        .transition()
        .duration(2000)
        .delay((i + 1) * 1700)
        .attrTween("d", function(d) {
          var start = { startAngle: 0, endAngle: 0 };
          var interpolate = d3.interpolate(start, d);
          return function(t) {
            return arcs[i](interpolate(t));
          };
        })
        .style("opacity", 1)
        .each(function(d, n) {
          var mainGradient = defs
            .append("linearGradient")
            .attr("id", "mainGradient-" + i);

          //Search pattern for everything between the start and the first capital L
          let firstArcSection = /(^.+?)L/;

          //Grab everything up to the first Line statement
          let newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
          //Replace all the comma's so that IE can handle it
          newArc = newArc.replace(/,/g, " ");

          //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
          //flip the end and start position
          if (d.endAngle > (90 * Math.PI) / 180) {
            let startLoc = /M(.*?)A/, //Everything between the first capital M and first capital A
              middleLoc = /A(.*?)0 0 1/, //Everything between the first capital A and 0 0 1
              endLoc = /0 0 1 (.*?)$/; //Everything between the first 0 0 1 and the end of the string (denoted by $)
            //Flip the direction of the arc by switching the start en end point (and sweep flag)
            //of those elements that are below the horizontal line
            let newStart = endLoc.exec(newArc)[1];
            let newEnd = startLoc.exec(newArc)[1];
            let middleSec = middleLoc.exec(newArc)[1];

            //Build up the new arc notation, set the sweep-flag to 0
            newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
          }

          // mainGradient.attr("gradientTransform", "rotate(" + angle + ")");

          // Create the steps of the main gradient. Each stop will be assigned
          // a class to style the stop using CSS.

          mainGradient
            .append("stop")
            .attr("class", "stop-right_" + i)
            .attr("offset", "0");

          mainGradient
            .append("stop")
            .attr("class", "stop-left_" + i)
            .attr("offset", "1");

          // These ids and classes are used to select the slices and the levels with d3
          let level = component.props.levels[i].label
            .replace(/[!%&'()*+./;<=>?\\,/:#@\t\r\n"[\]_\u007B-\u00BF-]/g, "")
            .toLowerCase()
            .replace(/  +/g, " ")
            .replace(" ", "-");
          d3.select(this).attr("class", "filled_" + i + " slice_" + level);

          let step = datasets[i].categories[n].step.label
            .replace(/[!%&'()*+./;<=>?\\,/:#@\t\r\n"[\]_\u007B-\u00BF-]/g, "")
            .toLowerCase()
            .replace(/  +/g, " ")
            .replace(" ", "-");
          d3.select(this).attr("id", step);

          //Create a new invisible arc that the text can flow along
          groups[i]
            .append("path")
            .attr("class", "hiddenDonutArcs")
            .attr("id", "pie-" + i + "-arc-" + n)
            .attr("d", newArc)
            .style("fill", "none");
        });

      //Append the label names on the outside
      groups[i]
        .selectAll(".donutText1")
        .data(pies[i](datasets[i].categories))
        .enter()
        .append("text")
        .style("font-size", "25.5em")
        .attr("class", "donutText1")
        .attr("fill", "black")
        .attr(
          "transform",
          "translate(" +
            containerW / 2 +
            "," +
            containerH / 2 +
            ") scale(0.047)"
        )
        //Move the labels below the arcs for those slices with an end angle greater than 90 degrees
        .attr("dy", function(d, n) {
          return d.endAngle > (90 * Math.PI) / 180 ? 270 : -71;
        })
        .append("textPath")
        .attr("startOffset", "50%")
        .style("text-anchor", "middle")
        .attr("fill-opacity", 0)
        .attr("font-weight", 700)
        .transition()
        .delay((i + 1) * 2200)
        .duration(1000)
        .attr("fill-opacity", 1)
        .attr("xlink:href", function(d, n) {
          return "#pie-" + i + "-arc-" + n;
        })
        .text(function(d) {
          return d.data.label;
        });

      //Append the bias number label inside the slices

      let totalBiases = this.props.biases.length;

      groups[i]
        .selectAll(".donutText2")
        .data(pies[i](datasets[i].categories))
        .enter()
        .append("text")
        .style("font-size", "19.5em")
        .attr("class", "donutText2")
        .attr(
          "transform",
          "translate(" +
            containerW / 2 +
            "," +
            containerH / 2 +
            ") scale(0.047)"
        )
        //Move the labels below the arcs for those slices with an end angle greater than 90 degrees
        .attr("dy", function(d, n) {
          return d.endAngle > (90 * Math.PI) / 180 ? -160 : 340;
        })
        .append("textPath")
        .attr("id", function(d, n) {
          return "g" + i + "-slice-" + n + "-text";
        })
        .attr("startOffset", function(d, n) {
          return d.endAngle > (180 * Math.PI) / 180 ? "90%" : "10%";
        })
        .style("text-anchor", "middle")
        .attr("fill", "white")
        .attr("fill-opacity", 0)
        .transition()
        .delay((i + 1) * 2300)
        .attr("fill-opacity", 1)
        .attr("xlink:href", function(d, n) {
          return "#pie-" + i + "-arc-" + n;
        })
        .text(function(d) {
          return d.data.biases.length + "/" + totalBiases;
        });
    }

    // BIAS CREATION & INSERTION

    for (let i = 0; i < datasets.length; i++) {
      for (let j = 0; j < datasets[i].categories.length; j++) {
        let circles = d3
          .select("#g" + i + "-slice-" + j)
          .append("g")
          .attr("class", "s-" + j + "-group-" + i)
          .selectAll("circle")
          .data(datasets[i].categories[j].biases)
          .enter()
          .append("circle")
          .attr("r", biasRadius)
          .attr("id", function(d) {
            return "circle-" + d.id;
          })
          .attr("fill", "white")
          .attr("stroke", "black")
          .attr("opacity", 0)
          .attr("stroke-width", 10)
          .attr("pointer-events", "none")
          .on("mouseover", function(d) {
            d3.select(this)
              .transition()
              .duration(150)
              .attr("r", biasRadius * 1.5);
            div
              .transition()
              .duration(400)
              .style("opacity", 1)
              .style("left", d3.event.pageX + "px")
              .style("top", d3.event.pageY + "px");
            div.html(
              `
              <div class="ui card">
                <div class="content center">
                  <h3 class="ui header" style="margin:0"><ul>[${d.bias_id}] ${d.cognitive_bias}</ul></h3>
                </div>
                <div class="content">
                  <div class="ui small feed">
                    <div class="event">
                      <div class="content">
                        <div class="summary">
                          <p>${d.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              `
            );
          })
          .on("mouseout", function(d) {
            d3.select(this)
              .transition()
              .duration(150)
              .attr("r", biasRadius);
            div
              .transition()
              .duration(10)
              .style("opacity", 0);
          })
          .on("click", function(d) {
            let count = component.state.selectedBiases.filter(bias => {
              if (bias.id !== d.id)
                d3.select(bias.me).attr("fill", style.colors.selectedBias);
              return bias.id === d.id;
            }).length;

            if (count === 0) {
              d["me"] = this;
              let updated = component.state.selectedBiases.concat(d);
              component.setState({ selectedBiases: updated });
              component.props.setSelected(updated);
            }

            component.props.openBiasSide(d);
          });

        let angle = 65 + j * 69;

        d3.select(".s-" + j + "-group-" + i).attr("transform", function(d) {
          return (
            "translate(" +
            circleArcs[i].centroid(d)[0] +
            "," +
            circleArcs[i].centroid(d)[1] * 0.99 +
            ") rotate(" +
            angle +
            ")"
          );
        });

        const simulation = d3
          .forceSimulation()
          .force("charge", d3.forceManyBody().strength(1)) // Nodes are attracted one each other of value is > 0
          .force(
            "collide",
            d3
              .forceCollide()
              .strength(1)
              .radius(biasRadius + biasRadius * 0.3)
              .iterations(1)
          )
          .alphaDecay(0.05);

        simulation
          .nodes(datasets[i].categories[j].biases)
          .on("tick", function() {
            circles.attr("transform", function(d) {
              return "translate(" + d.x + "," + d.y + ")";
            });
          });

        circles.each(function(d, i) {
          simulation
            .force(
              "x",
              d3
                .forceX()
                .strength(0.7)
                .x(d.x)
            )
            .force(
              "y",
              d3
                .forceY()
                .strength(0.0)
                .y(d.y)
            );
        });

        // let dragControls = d3
        //   .drag()
        //   .on("start", dragstarted)
        //   .on("drag", dragged)
        //   .on("end", dragended);

        // dragControls(circles);

        // function dragstarted(d) {
        //   if (!d3.event.active) simulation.alphaTarget(0.03).restart();
        //   simulation
        //     .force("link", null)
        //     .force("charge", null)
        //     .force("collide", null);
        //   d.fx = d.x;
        //   d.fy = d.y;
        //   div
        //     .transition()
        //     .duration(100)
        //     .style("opacity", 0);
        // }

        // function dragged(d) {
        //   d.fx = d3.event.x;
        //   d.fy = d3.event.y;
        //   div
        //     .transition()
        //     .duration(100)
        //     .style("opacity", 0);
        // }

        // function dragended(d) {
        //   if (!d3.event.active) simulation.alphaTarget(0);
        //   d.fx = null;
        //   d.fy = null;
        //   div
        //     .transition()
        //     .duration(100)
        //     .style("opacity", 0);
        // }
      }
    }

    svg
      .selectAll("circle")
      .transition()
      .delay(8000)
      .duration(1000)
      .ease(d3.easeLinear)
      .style("opacity", 1);

    var t = d3.timer(function(elapsed) {
      if (elapsed >= 10000) {
        t.stop();

        /*
          Returns the coordinates of the passed element regardless of the transormation that have been
          applied during the whole execution.
        */
        function getScreenCoords(x, y, ctm) {
          var xn = ctm.e + x * ctm.a + y * ctm.c;
          var yn = ctm.f + x * ctm.b + y * ctm.d;
          return { x: xn, y: yn };
        }

        let markerDefs = d3.select("#mainContainer-2").append("svg:defs");

        component.props.links.forEach((tour, index) => {
          d3.select("#mainContainer-2")
            .append("g")
            .attr("id", "tour-" + component.props.tours[index].id)
            .attr("class", "tours")
            .selectAll("link")
            .data(tour)
            .enter()
            .append("line")
            .attr("class", function(d, i) {
              return (
                "link tour-" + component.props.tours[index].id + "-link-" + i
              );
            })
            .attr("x1", function(l, index) {
              let biases = d3.select("#mainContainer-2").selectAll("circle");
              let id = null;

              biases.each(function(d, index) {
                if (d.id === l.source) {
                  id = d.id;
                }
              });

              let node = d3.select("#circle-" + id).node();

              var circle = node,
                cx = +circle.getAttribute("cx"),
                cy = +circle.getAttribute("cy"),
                ctm = circle.getCTM(),
                coords = getScreenCoords(cx, cy, ctm);

              d3.select(this).attr("y1", coords.y);

              return coords.x;
            })
            .attr("x2", function(l) {
              let biases = d3.select("#mainContainer-2").selectAll("circle");
              let id = null;

              biases.each(function(d, index) {
                if (d.id === l.target) {
                  id = d.id;
                }
              });

              let node = d3.select("#circle-" + id).node();

              var circle = node,
                cx = +circle.getAttribute("cx"),
                cy = +circle.getAttribute("cy"),
                ctm = circle.getCTM(),
                coords = getScreenCoords(cx, cy, ctm);

              d3.select(this).attr("y2", coords.y);

              return coords.x;
            })
            .attr("fill", "black")
            .attr("stroke", style.colors.tourPathColor)
            .attr("stroke-width", style.sizes.pathStroke)
            // .style("stroke-dasharray", "6, 6")
            .style("opacity", 0)
            .style("display", "none")
            .attr("marker-end", (d, i) => {
              markerDefs
                .append("svg:marker")
                .attr(
                  "id",
                  "arrow-tour-" + component.props.tours[index].id + "-link-" + i
                )
                .attr("class", "arrows")
                .attr("viewBox", "0 -5 10 10")
                .attr("refX", 4) //so that it comes towards the center.
                .attr("markerWidth", 2)
                .attr("markerHeight", 3.5)
                .attr("orient", "auto")
                .style("opacity", 0)
                .attr("fill", style.colors.tourArrowColor)
                .append("svg:path")
                .attr("d", "M0,-5L10,0L0,5");

              return `url(#arrow-tour-${component.props.tours[index].id}-link-${i})`;
            })
            .on("mouseover", function(d, i) {
              d3.select(this)
                .attr("cursor", "pointer")
                .transition()
                .duration(200)
                .attr("stroke", style.colors.tourPathColorHovered);

              d3.select(
                "#arrow-tour-" + component.props.tours[index].id + "-link-" + i
              )
                .attr("cursor", "pointer")
                .transition()
                .duration(200)
                .attr("fill", style.colors.tourArrowColorHovered);
            })
            .on("mouseout", function(d, i) {
              d3.select(this)
                .attr("cursor", "initial")
                .transition()
                .duration(200)
                .attr("stroke", style.colors.tourPathColor);

              d3.select(
                "#arrow-tour-" + component.props.tours[index].id + "-link-" + i
              )
                .attr("cursor", "initial")
                .transition()
                .duration(200)
                .attr("fill", style.colors.tourArrowColor);
            })
            .on("click", function(d, i) {
              component.props.openTourSide(component.props.tours[index]);
            });

          let linksGroup = d3.select(
            "#tour-" + component.props.tours[index].id
          );
          linksGroup.attr("transform", function() {
            let factor = 1.0805;
            let multiplier = 2.85;

            // if(Math.min(containerW, containerH))

            var circle = d3.select(this).node(),
              cx = +circle.getAttribute("cx"),
              cy = +circle.getAttribute("cy"),
              ctm = circle.getCTM(),
              coords = getScreenCoords(cx, cy, ctm);

            let x = coords.x + multiplier * factor;
            let y = coords.y + multiplier * factor;

            return (
              "translate(" +
              -x +
              "," +
              -y +
              ") scale(" +
              factor +
              "," +
              factor +
              ")"
            );
          });
        });

        d3.selectAll("*").attr("pointer-events", "auto");
      }
    });

    var t2 = d3.timer(function(elapsed) {
      if (elapsed >= 17000) {
        t2.stop();
        // component.checkCookie();
        zoom.filter(function() {
          return !d3.event.ctrlKey && !d3.event.button;
        });
        component.props.setDisabled(false);
        component.props.showWelcomeModal(true);
      }
    });
  };

  autoBox() {
    const { x, y, width, height } = this.getBBox();
    return [x, y, width, height];
  }

  handleChipDelete = (event, toDelete) => {
    let defaultRadius = d3.select(toDelete.me).attr("r");

    d3.select(toDelete.me)
      .transition()
      .duration(200)
      .attr("r", defaultRadius / 1.6);
    d3.select(toDelete.me).attr("fill", "white");

    let updated = this.state.selectedBiases.filter(bias => {
      return bias.id !== toDelete.id;
    });

    this.setState({
      selectedBiases: updated
    });

    this.props.setSelected(updated);
  };

  // setCookie = (cname, cvalue, ex) => {
  //   var d = new Date();
  //   d.setTime(d.getTime() + ex * 24 * 60 * 60 * 1000);
  //   var expires = "expires=" + d.toUTCString();
  //   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  // };

  // getCookie = cname => {
  //   var name = cname + "=";
  //   var ca = document.cookie.split(";");
  //   for (var i = 0; i < ca.length; i++) {
  //     var c = ca[i];
  //     while (c.charAt(0) === " ") {
  //       c = c.substring(1);
  //     }
  //     if (c.indexOf(name) === 0) {
  //       return c.substring(name.length, c.length);
  //     }
  //   }
  //   return "";
  // };

  // checkCookie = () => {
  //   var tutorial = this.getCookie("tutorial");
  //   if (tutorial !== "do not display") {
  //     this.props.tutorialOn(true);
  //     tutorial = "do not display";
  //     this.setCookie("tutorial", tutorial, 365);
  //   }
  // };

  render() {
    return (
      <div>
        <BiasMapToolbar
          selectedBiases={this.state.selectedBiases}
          handleChipDelete={this.handleChipDelete}
          openBiasSide={this.props.openBiasSide}
          disabled={this.props.disabled}
        />
        <div className="b_container">
          <div
            id="biasmap-container"
            style={{
              width: "100%",
              height: "80vh"
            }}
            ref={el => (this.componentRef = el)}
          >
            <div
              ref="child"
              id="biasmap"
              style={{
                width: "100%",
                height: "80vh"
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

export default BiasMap;

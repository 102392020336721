import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchBiases, fetchLevels, fetchMysc } from "../actions";
import * as d3 from "d3";
import ModalVideo from "react-modal-video";

import BiasMap from "./BiasMap";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import style from "../utils/styles.js";
import "./BiasMapWrapper.css";
import "semantic-ui-css/semantic.min.css";

/* 
  This component acts as a wrapper to the BiasMap component. 
  It fetches and manages data from the API. 
*/
class BiasMapWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      biases: [],
      levels: [],
      process: {},
      firstRendering: false,
      ready: false,
      selectedBias: {},
      biasRadius: 0,
      selectedCategory: {},
      selectedTour: {},
      next: false,
      isOpen: false
    };
  }

  async componentDidMount() {
    let biases = await this.getBiases();
    let levels = await this.getLevels();
    let process = await this.getMysc();

    this.setState({
      biases: biases,
      levels: levels,
      process: process,
      ready: true,
      firstRendering: true
    });
    this.setState({
      firstRendering: false
    });
  }

  /* Fetched data processing */

  getBiases = async () => {
    let biases = [];
    let resp = await this.props.fetchBiases();
    biases = resp.payload.data;

    this.setState({});
    if (resp.payload.headers["x-wp-totalpages"] > 1) {
      for (let i = 2; i <= resp.payload.headers["x-wp-totalpages"]; i++) {
        resp = await this.props.fetchBiases("&page=".concat(i));
        biases = [...biases, ...resp.payload.data];
      }
    }

    return biases;
  };

  getLevels = async () => {
    let levels = [];
    let resp = await this.props.fetchLevels();
    levels = resp.payload.data;

    this.setState({});
    if (resp.payload.headers["x-wp-totalpages"] > 1) {
      for (let i = 2; i <= resp.payload.headers["x-wp-totalpages"]; i++) {
        resp = await this.props.fetchLevels("&page=".concat(i));
        levels = [...levels, ...resp.payload.data];
      }
    }

    return levels;
  };

  getMysc = async () => {
    let mysc = [];
    let resp = await this.props.fetchMysc();

    mysc = resp.payload.data;

    this.setState({});

    let process = {};
    mysc.forEach(item => {
      if (item.label === "Process") process = item;
    });

    return process;
  };

  /* Sidemenu management */

  openBiasSide = (data, next = false, previousId = null) => {
    this.closeBiasSide();

    if (next) this.setState({ next: true });

    this.setState({ selectedBias: data });

    let defaultRadius = d3.select("#circle-" + data.id).attr("r") / 1.7;
    this.setState({ biasRadius: defaultRadius });

    d3.select("#circle-" + data.id).attr("fill", "black");

    if (next)
      d3.select("#circle-" + data.id).attr(
        "stroke",
        style.colors.currentBiasStroke
      );

    this.closeCategorySide();
    this.closeTourSide();

    document.getElementById("bias-sidenav").style.opacity = 1;
    document.getElementById("bias-sidenav").style.width = "400px";
    document.getElementById("bias-sidenav").style.height = "100%";
  };

  closeBiasSide = () => {
    let id = this.state.selectedBias.id;
    d3.select("#circle-" + id).attr("fill", style.colors.selectedBias);

    if (this.state.next)
      d3.select("#circle-" + id)
        .transition()
        .duration(200)
        .attr("stroke", "black");

    document.getElementById("bias-sidenav").style.opacity = 1;
    document.getElementById("category-sidenav").style.opacity = 0;
    document.getElementById("bias-sidenav").style.width = "0";
    document.getElementById("bias-sidenav").style.height = "0";
  };

  openCategorySide = selectedCategory => {
    this.setState({ selectedCategory: selectedCategory });

    if (
      Object.keys(this.state.selectedBias).length !== 0 &&
      this.state.selectedBias.constructor === Object
    )
      this.closeBiasSide();
    this.closeTourSide();

    document.getElementById("category-sidenav").style.opacity = 1;
    document.getElementById("category-sidenav").style.width = "400px";
    document.getElementById("category-sidenav").style.height = "100%";
  };

  closeCategorySide = () => {
    document.getElementById("bias-sidenav").style.opacity = 0;
    document.getElementById("category-sidenav").style.width = "0";
    document.getElementById("category-sidenav").style.height = "0";
  };

  openTourSide = selectedTour => {
    this.setState({ selectedTour: selectedTour });

    if (
      Object.keys(this.state.selectedBias).length !== 0 &&
      this.state.selectedBias.constructor === Object
    )
      this.closeBiasSide();
    this.closeCategorySide();

    document.getElementById("tour-sidenav").style.opacity = 1;
    document.getElementById("tour-sidenav").style.width = "400px";
    document.getElementById("tour-sidenav").style.height = "100%";
  };

  closeTourSide = () => {
    document.getElementById("bias-sidenav").style.opacity = 0;
    document.getElementById("tour-sidenav").style.width = "0";
    document.getElementById("tour-sidenav").style.height = "0";
  };

  /* Bias interaction */

  biasMouseOver = (event, bias) => {
    let defaultRadius = d3.select("#circle-" + bias.id).attr("r");
    this.setState({ biasRadius: defaultRadius });

    d3.select("#circle-" + bias.id)
      .transition()
      .duration(200)
      .attr("r", defaultRadius * 1.7);
  };

  biasMouseOut = (event, bias) => {
    d3.select("#circle-" + bias.id)
      .transition()
      .duration(200)
      .attr("r", this.state.biasRadius);
  };

  /* Tour navigation */

  nextPressed = () => {
    let index = null;
    let tour = this.state.selectedTour;

    tour.biases.forEach((bias, i) => {
      if (bias.id === this.state.selectedBias.id) index = i;
    });

    if (index !== null && index + 1 < tour.biases.length) {
      let nextBias = d3
        .select("#circle-" + tour.biases[index + 1].id)
        .data()[0];
      this.openBiasSide(nextBias, true, index);
    }
  };

  previousPressed = () => {
    let index = null;
    let tour = this.state.selectedTour;

    tour.biases.forEach((bias, i) => {
      if (bias.id === this.state.selectedBias.id) index = i;
    });

    if (index !== null && index - 1 >= 0) {
      let previousBias = d3
        .select("#circle-" + tour.biases[index - 1].id)
        .data()[0];
      this.openBiasSide(previousBias, true);
    }
  };

  /* Utility methods */

  isInTour = tourBiases => {
    return (
      tourBiases.filter(bias => {
        return bias.id === this.state.selectedBias.id;
      }).length > 0
    );
  };

  /* react-modal-video opening */

  openModal = () => {
    this.setState({ isOpen: true });
  };

  render() {
    let category = "";
    let types = "";
    let websites = [];
    let biases = [];
    let tourBiases = [];

    if (
      Object.keys(this.state.selectedBias).length !== 0 &&
      this.state.selectedBias.constructor === Object
    ) {
      // CATEGORIES

      if (
        this.state.selectedBias.category &&
        this.state.selectedBias.category.length > 0
      ) {
        category = this.state.selectedBias.category[0].label;
      }

      // TYPES
      if (
        this.state.selectedBias.b_type &&
        this.state.selectedBias.b_type.length > 0
      ) {
        this.state.selectedBias.b_type.forEach((type, index) => {
          types += type.label;
          if (index < this.state.selectedBias.b_type.length - 1) types += ", ";
        });
      }

      // WEBSITES
      if (this.state.selectedBias.websites)
        websites = this.state.selectedBias.websites.split(" ");
    }

    if (
      Object.keys(this.state.selectedCategory).length !== 0 &&
      this.state.selectedBias.constructor === Object
    ) {
      if (this.state.selectedCategory.biases)
        biases = this.state.selectedCategory.biases;
    }

    if (
      Object.keys(this.state.selectedTour).length !== 0 &&
      this.state.selectedBias.constructor === Object
    ) {
      if (this.state.selectedTour.biases !== undefined)
        tourBiases = this.state.selectedTour.biases;
    }

    return (
      <div>
        <div>
          <div id="bias-sidenav" className="sidenav">
            <button className="closebtn" onClick={this.closeBiasSide}>
              &times;
            </button>
            {this.isInTour(tourBiases) ? (
              <div className="ui container" style={{ textAlign: "center" }}>
                <div className="column centered">
                  <div className="content">
                    <button
                      className="ui red button"
                      style={{ width: "150px" }}
                      onClick={this.previousPressed}
                    >
                      Previous
                    </button>
                    <button
                      className="ui green button"
                      style={{ width: "150px" }}
                      onClick={this.nextPressed}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="ui container" style={{ padding: "10px" }}>
              <div className="column centered">
                <div className="ui card" style={{ width: "100%" }}>
                  <div className="content">
                    <h2 className="ui header">
                      [{this.state.selectedBias.bias_id}]{" "}
                      {this.state.selectedBias.cognitive_bias}
                    </h2>
                    {this.state.selectedBias.video !== "" ? (
                      <div className="ui four column grid">
                        <div className="row">
                          <div
                            className="sixteen wide column"
                            style={{ textAlign: "center" }}
                          >
                            <Button
                              variant="outlined"
                              className="ui secondary basic button"
                              onClick={this.openModal}
                            >
                              <i
                                className="video icon"
                                style={{ margin: 0 }}
                              ></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="ui divider" />
                    <div className="content">
                      <h5 className="ui sub header">Category</h5>
                      <div className="ui small feed">
                        <div className="event">
                          <div className="content">
                            <div className="summary">{category}</div>
                          </div>
                        </div>
                      </div>
                      <h5 className="ui sub header">Type</h5>
                      <div className="ui small feed">
                        <div className="event">
                          <div className="content">
                            <div className="summary">{types}</div>
                          </div>
                        </div>
                      </div>
                      <div className="ui divider" />
                      <h4 className="ui sub header">Description</h4>
                      <div className="ui small feed">
                        <div className="event">
                          <div className="content">
                            <div className="summary">
                              {this.state.selectedBias.description}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ui divider" />
                      <h4 className="ui sub header">Managerial Axis</h4>
                      <div className="ui small feed">
                        <div className="event">
                          <div className="content">
                            {this.state.selectedBias.managerial_axis}
                          </div>
                        </div>
                      </div>
                      <h4 className="ui sub header">Psychological Axis</h4>
                      <div className="ui small feed">
                        <div className="event">
                          <div className="content">
                            {this.state.selectedBias.psychological_axis}
                          </div>
                        </div>
                      </div>
                      <div className="ui divider" />
                      <h5 className="ui sub header">Reference</h5>
                      <div className="ui small feed">
                        <div className="event">
                          <div className="content">
                            {this.state.selectedBias.reference}
                          </div>
                        </div>
                      </div>
                      <h5 className="ui sub header">First mentioned</h5>
                      <div className="ui small feed">
                        <div className="event">
                          <div className="content">
                            {this.state.selectedBias.first_mentioned}
                          </div>
                        </div>
                      </div>
                      <div className="ui divider" />
                      <h5 className="ui sub header">Websites</h5>
                      <div className="ui bulleted list">
                        {websites.map((w, index) => {
                          if (w === "") return <div key={w}></div>;
                          return (
                            <div key={w} className="item">
                              <a
                                href={w}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {w}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="extra content">
                    <i className="quote right icon"></i>
                    {this.state.selectedBias.citations} citations
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="category-sidenav" className="sidenav">
          <button className="closebtn" onClick={this.closeCategorySide}>
            &times;
          </button>
          <div className="ui container" style={{ padding: "10px" }}>
            <div className="column centered">
              <div className="ui card" style={{ width: "100%" }}>
                <div className="content">
                  <h2 className="ui header">
                    {this.state.selectedCategory.label}
                  </h2>
                  <div className="ui divider" />
                  <div className="content">
                    <h5 className="ui sub header">Description</h5>
                    <div className="ui small feed">
                      <div className="event">
                        <div className="content">
                          <div className="summary">
                            {this.state.selectedCategory.description}
                          </div>
                          <br />
                          {this.state.selectedCategory.additional}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ui divider" />
                  <div className="content">
                    <div className="ui small feed">
                      <div className="event">
                        <div className="content">
                          <ExpansionPanel
                            expanded
                            elevation={0}
                            style={{
                              width: "100%",
                              padding: "0",
                              border: "1px solid #c5cae9",
                              borderRadius: "10px"
                            }}
                          >
                            <ExpansionPanelSummary
                              expandIcon={null}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>Biases ({biases.length})</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{ padding: "5px" }}>
                              <Paper elevation={0} style={{ margin: "10px" }}>
                                <ul className="ui list">
                                  {biases.map(bias => {
                                    return (
                                      <div
                                        key={bias.bias_id}
                                        onMouseEnter={event =>
                                          this.biasMouseOver(event, bias)
                                        }
                                        onMouseOut={event =>
                                          this.biasMouseOut(event, bias)
                                        }
                                        onClick={event => {
                                          let b = d3.select(
                                            "#circle-" + bias.id
                                          );
                                          this.openBiasSide(b.data()[0]);
                                        }}
                                      >
                                        <li>
                                          <Typography id="bias">
                                            {bias.cognitive_bias}
                                          </Typography>
                                        </li>
                                      </div>
                                    );
                                  })}
                                </ul>
                              </Paper>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="tour-sidenav" className="sidenav">
          <button className="closebtn" onClick={this.closeTourSide}>
            &times;
          </button>
          <div className="ui container" style={{ padding: "10px" }}>
            <div className="column centered">
              <div className="ui card" style={{ width: "100%" }}>
                <div className="content">
                  <h2 className="ui header">{this.state.selectedTour.label}</h2>
                  <div className="ui divider" />
                  <div className="content">
                    <div className="ui small feed">
                      <div className="event">
                        <div className="content">
                          <div className="content">
                            <h5 className="ui sub header">Description</h5>
                            <div className="ui small feed">
                              <div className="event">
                                <div className="content">
                                  <div className="summary">
                                    {this.state.selectedTour.description}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ExpansionPanel
                            elevation={0}
                            style={{
                              width: "100%",
                              padding: "0",
                              border: "1px solid #c5cae9",
                              borderRadius: "10px"
                            }}
                            expanded
                          >
                            <ExpansionPanelSummary
                              expandIcon={null}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>
                                Biases ({tourBiases.length})
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{ padding: "5px" }}>
                              <Paper elevation={0} style={{ margin: "10px" }}>
                                <ul className="ui list">
                                  {tourBiases.map((bias, index) => {
                                    if (index === tourBiases.length - 1)
                                      return (
                                        <div
                                          key={bias.bias_id}
                                          style={{
                                            textAlign: "center"
                                          }}
                                        >
                                          <div
                                            className="item"
                                            style={{
                                              margin: "5px"
                                            }}
                                            onMouseEnter={event =>
                                              this.biasMouseOver(event, bias)
                                            }
                                            onMouseOut={event =>
                                              this.biasMouseOut(event, bias)
                                            }
                                            onClick={event => {
                                              let b = d3.select(
                                                "#circle-" + bias.id
                                              );
                                              this.openBiasSide(b.data()[0]);
                                            }}
                                          >
                                            <Typography
                                              style={{ cursor: "pointer" }}
                                            >
                                              {bias.cognitive_bias}
                                            </Typography>
                                          </div>
                                        </div>
                                      );

                                    return (
                                      <div
                                        key={bias.bias_id}
                                        style={{
                                          textAlign: "center"
                                        }}
                                      >
                                        <div
                                          className="item"
                                          style={{ margin: "5px" }}
                                          onMouseEnter={event =>
                                            this.biasMouseOver(event, bias)
                                          }
                                          onMouseOut={event =>
                                            this.biasMouseOut(event, bias)
                                          }
                                          onClick={event => {
                                            let b = d3.select(
                                              "#circle-" + bias.id
                                            );
                                            this.openBiasSide(
                                              b.data()[0],
                                              true
                                            );
                                          }}
                                        >
                                          <Typography id="bias">
                                            {bias.cognitive_bias}
                                          </Typography>
                                        </div>
                                        <i className="angle double down icon"></i>
                                      </div>
                                    );
                                  })}
                                </ul>
                              </Paper>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId={this.state.selectedBias.video}
          onClose={() => this.setState({ isOpen: false })}
        />
        <BiasMap
          openBiasSide={this.openBiasSide}
          openCategorySide={this.openCategorySide}
          openTourSide={this.openTourSide}
          biases={this.state.biases}
          levels={this.state.levels}
          process={this.state.process}
          ready={this.state.ready}
          firstRendering={this.state.firstRendering}
          links={this.props.links}
          tours={this.props.tours}
          disabled={this.props.disabled}
          setDisabled={this.props.setDisabled}
          tutorialOn={this.props.tutorialOn}
          setSelected={this.props.setSelected}
          showWelcomeModal={this.props.showWelcomeModal}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchBiases, fetchLevels, fetchMysc }, dispatch);
}

export default connect(null, mapDispatchToProps)(BiasMapWrapper);

import React from "react";
import * as d3 from "d3";

import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// import ReactToPrint from "react-to-print";

class BiasMapToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      biasRadius: 0,
      anchorEl: null
    };
  }

  handleChipOnMouseEnter = (event, bias) => {
    let defaultRadius = d3.select(bias.me).attr("r");

    this.setState({ biasRadius: defaultRadius });
    d3.select(bias.me)
      .transition()
      .duration(200)
      .attr("r", defaultRadius * 1.6);
  };

  handleChipOnMouseLeave = (event, bias) => {
    d3.select(bias.me)
      .transition()
      .duration(200)
      .attr("r", this.state.biasRadius);
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    return (
      <ExpansionPanel
      className="selected"
        elevation={0}
        disabled={this.props.disabled}
        style={{
          color: "white",
          backgroundColor: "#3f51b5",
          width: "100%",
          margin: "5px",
          padding: "0",
          border: "1px solid white",
          borderRadius: "10px",
          top: "-8px",
          right: "5px"
        }}
      >
        <ExpansionPanelSummary
          style={{ margin: "0" }}
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Selected Biases ({this.props.selectedBiases.length})
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: "5px" }}>
          <Paper
            elevation={0}
            style={{
              backgroundColor: "#3f51b5",
              padding: "1px"
            }}
          >
            {this.props.selectedBiases.map(bias => {
              return (
                <Chip
                  size="small"
                  style={{ margin: "5px" }}
                  key={bias.bias_id}
                  label={bias.cognitive_bias}
                  onClick={event => this.props.openBiasSide(bias)}
                  onDelete={event => this.props.handleChipDelete(event, bias)}
                  onMouseEnter={event =>
                    this.handleChipOnMouseEnter(event, bias)
                  }
                  onMouseLeave={event =>
                    this.handleChipOnMouseLeave(event, bias)
                  }
                />
              );
            })}
          </Paper>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default BiasMapToolbar;

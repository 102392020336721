import { FETCH_BIASES } from "../actions";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_BIASES:
      return [action.payload.data, ...state];
    default:
      break;
  }
  return state;
}

import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import * as d3 from "d3";

import { saveSvgAsPng } from "save-svg-as-png";

import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import "./BiasMapDownload.css";

import RobotoBold from "../utils/fonts/Roboto-Bold.ttf";
import RobotoRegular from "../utils/fonts/Roboto-Regular.ttf";
import RobotoLight from "../utils/fonts/Roboto-Light.ttf";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font
} from "@react-pdf/renderer";

Font.register({
  family: "Roboto-Light",
  src: RobotoLight
});
Font.register({
  family: "Roboto-Medium",
  src: RobotoRegular
});
Font.register({
  family: "Roboto-Bold",
  src: RobotoBold
});

const documentStyles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 30
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  biasContainer: {
    backgroundColor: "#f6f6f5",
    fontFamily: "Roboto-Light",
    display: "flex",
    flexDirection: "row",
    padding: 5,
    borderColor: "black",
    borderWidth: StyleSheet.hairlineWidth,
    margin: 5
  },
  biasDetails: {
    display: "flex",
    marginLeft: 5
  },
  biasTitle: {
    fontFamily: "Roboto-Bold",
    fontSize: 13,
    marginBottom: 5,
    borderBottomColor: "black",
    borderBottomWidth: StyleSheet.hairlineWidth
  },
  biasSubtitle: {
    fontFamily: "Roboto-Medium",
    fontSize: 9,
    marginBottom: 4,
    padding: 5,
    color: "#3f51b5"
  },
  biasOverview: {
    fontFamily: "Roboto-Light",
    fontSize: 9,
    marginLeft: 5,
    marginBottom: 5
  },
  image: {
    height: 200,
    width: 150
  },
  subtitle: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: 150,
    alignItems: "center",
    marginBottom: 5
  },
  vote: {
    display: "flex",
    flexDirection: "row"
  },
  rating: {
    height: 10,
    width: 10
  },
  vote_text: {
    fontSize: 10
  },
  vote_pop: {
    fontSize: 10,
    padding: 2,
    backgroundColor: "#61C74F",
    color: "#fff"
  },
  vote_pop_text: {
    fontSize: 10,
    marginLeft: 4
  },
  overviewContainer: {
    minHeight: 110
  },
  detailsFooter: {
    display: "flex",
    flexDirection: "row"
  },
  lang: {
    fontSize: 8,
    fontWeight: 700
  },
  vote_average: {
    fontSize: 8,
    marginLeft: 4,
    fontWeight: "bold"
  }
});

const styles = {
  tooltip: {
    backgroundColor: "white",
    border: "1px solid #bdbdbd"
  }
};

const CustomTooltip = withStyles(styles)(Tooltip);

class BiasMapDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      biasRadius: 0,
      selectedBiases: [],
      modal: false
    };
  }

  onSuccess = () => {
    console.log("test");
  };

  handleClick = event => {
    this.forceUpdate();
    let selected = this.props.selected;
    console.log(selected.length);
    console.log(this.state.selectedBiases.length);

    if (selected.length !== 0) {
      this.setState({ modal: true });
    }

    this.setState({
      selectedBiases: selected
    });
  };

  onStart = () => {
    this.setState({ modal: false });
  };

  onStop = () => {
    this.setState({ modal: false });
  };

  getLevel = category => {
    let levelId = Object.keys(category.level)[0];

    let level = category.level[levelId].label;
    return level;
  };

  doc = () => (
    <Document onRenderSuccess={this.onSuccess}>
      <Page style={documentStyles.page} wrap>
        <View style={documentStyles.biasDetails}>
          {this.state.selectedBiases ? (
            this.state.selectedBiases.map((d, index) => {
              return (
                <View
                  key={index}
                  wrap={false}
                  style={documentStyles.biasContainer}
                >
                  <View style={documentStyles.biasDetails}>
                    <Text style={documentStyles.biasTitle}>
                      [{d.bias_id}] {d.cognitive_bias}
                    </Text>
                    <View
                      style={[
                        documentStyles.subtitle,
                        {
                          borderBottomColor: "black",
                          borderBottomWidth: StyleSheet.hairlineWidth
                        }
                      ]}
                    >
                      <Text style={documentStyles.biasSubtitle}>LEVEL: </Text>
                      <Text
                        style={[
                          documentStyles.biasOverview,
                          { marginRight: 20, fontWeight: 300 }
                        ]}
                      >
                        {this.getLevel(d.category[0])}
                      </Text>
                      <Text style={documentStyles.biasSubtitle}>
                        CATEGORY:{" "}
                      </Text>
                      <Text
                        style={[
                          documentStyles.biasOverview,
                          { marginRight: 20, fontWeight: 300 }
                        ]}
                      >
                        {d.category[0].label}
                      </Text>
                    </View>
                    <Text style={documentStyles.biasSubtitle}>TYPE: </Text>
                    <Text key={index} style={documentStyles.biasOverview}>
                      {d.b_type.map((type, index) => {
                        return index < d.b_type.length - 1
                          ? type.label + ", "
                          : type.label;
                      })}
                    </Text>
                    <View
                      style={[documentStyles.subtitle, { marginBottom: 2 }]}
                    >
                      <Text style={documentStyles.biasSubtitle}>
                        DESCRIPTION:
                      </Text>
                    </View>
                    <Text
                      style={[
                        documentStyles.biasOverview,
                        { marginRight: 20, fontWeight: 100 }
                      ]}
                    >
                      {d.description}
                    </Text>
                    <View
                      style={[
                        documentStyles.subtitle,
                        {
                          marginBottom: 2,
                          borderTopColor: "black",
                          borderTopWidth: StyleSheet.hairlineWidth
                        }
                      ]}
                    >
                      <Text style={documentStyles.biasSubtitle}>
                        MANAGERIAL AXIS:
                      </Text>
                    </View>
                    <Text
                      style={[
                        documentStyles.biasOverview,
                        { marginRight: 20, fontWeight: 100 }
                      ]}
                    >
                      {d.managerial_axis}
                    </Text>
                    <View
                      style={[documentStyles.subtitle, { marginBottom: 2 }]}
                    >
                      <Text style={documentStyles.biasSubtitle}>
                        PSYCHOLOGICAL AXIS:
                      </Text>
                    </View>
                    <Text
                      style={[
                        documentStyles.biasOverview,
                        { marginRight: 20, fontWeight: 100 }
                      ]}
                    >
                      {d.psychological_axis}
                    </Text>
                    <View
                      style={[
                        documentStyles.subtitle,
                        {
                          marginBottom: 2,
                          borderTopColor: "black",
                          borderTopWidth: StyleSheet.hairlineWidth
                        }
                      ]}
                    >
                      <Text style={documentStyles.biasSubtitle}>
                        REFERENCE:
                      </Text>
                    </View>
                    <Text
                      style={[
                        documentStyles.biasOverview,
                        { marginRight: 20, fontWeight: 100 }
                      ]}
                    >
                      {d.reference}
                    </Text>
                    <View
                      style={[documentStyles.subtitle, { marginBottom: 2 }]}
                    >
                      <Text style={documentStyles.biasSubtitle}>
                        FIRST MENTIONED:
                      </Text>
                    </View>
                    <Text
                      style={[
                        documentStyles.biasOverview,
                        { marginRight: 20, fontWeight: 100 }
                      ]}
                    >
                      {d.first_mentioned}
                    </Text>
                  </View>
                </View>
              );
            })
          ) : (
            <Text style={documentStyles.biasTitle}>No biases selected</Text>
          )}
        </View>
      </Page>
    </Document>
  );

  savePng = () => {
    this.setState({ anchorEl: null });
    saveSvgAsPng(d3.select("#biasmap-svg").node(), "bias-map.png");

    // d3.select(".central-flow").style("fill", "none");
    // d3.select(".central-flow").style("fill", "url(#flow)");
  };

  render() {
    let document = this.doc();

    return (
      <div>
        {/* <button onClick={this.savePng}>DOWNLOAD</button> */}
        <IconButton
          className="export"
          id="button__download"
          aria-controls="simple-menu"
          aria-haspopup="true"
          disabled={this.props.disabled}
          onClick={this.handleClick}
          variant="contained"
          style={{ backgroundColor: "#4caf50", marginLeft: "23px" }}
        >
          <CustomTooltip
            title={
              this.props.selected.length !== 0 ? (
                <div className="content">
                  <div className="ui small feed">
                    <div className="event">
                      <div className="content">
                        <div className="summary" style={{ fontSize: "0.8rem" }}>
                          <p>Generate your personalized map</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="content">
                  <div className="ui small feed">
                    <div className="event">
                      <div className="content">
                        <div className="summary" style={{ fontSize: "0.8rem" }}>
                          <p>
                            Generate your personalized map, please select at
                            least one bias...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            placement="left"
          >
            <GetAppRoundedIcon style={{ color: "white" }} />
          </CustomTooltip>
        </IconButton>
        <Dialog
          onClose={() => this.setState({ modal: false })}
          aria-labelledby="simple-dialog-title"
          open={this.state.modal}
        >
          <DialogTitle>Download your biases!</DialogTitle>
          <DialogContent>
            <DialogActions>
              <Button onClick={this.onStop} color="primary" autoFocus>
                <PDFDownloadLink document={document} fileName="bias-map.pdf">
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download now!"
                  }
                </PDFDownloadLink>
              </Button>
              <Button onClick={this.onStop} color="secondary" autoFocus>
                Cancel
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default BiasMapDownload;
